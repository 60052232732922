import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { UniversalNavButton, UniversalNavContainer, UniversalIcon } from "./UniversalNav.styled";

function NavButton({ location, children }) {
  const navigate = useNavigate();
  const currentPath = useLocation();

  const isActive = location === "/" && currentPath.pathname === "/" || location !== "/" && currentPath.pathname.startsWith(location);

  return (
    <UniversalNavButton
      onClick={() => navigate(location)}
      active={isActive}>
      {children}
    </UniversalNavButton>
  )
}

function UniversalNav() {

  return (
    <UniversalNavContainer>
      <NavButton location={"/"}><UniversalIcon icon="material-symbols:dashboard-customize-outline-rounded" color="white" />Dashboard</NavButton>
      <NavButton location={"/operations"}><UniversalIcon icon="bx:category" color="white" />Operations</NavButton>
      <NavButton location={"/bots"}><UniversalIcon icon="mdi:head-cog-outline" color="white" />Bots</NavButton>
      <NavButton location={"/chats"}><UniversalIcon icon="material-symbols:chat-bubble-outline" color="white" />Chats</NavButton>
      <NavButton location={"/suspects"}><UniversalIcon icon="ic:outline-people" color="white" />Suspects</NavButton>
    </UniversalNavContainer>
  );
}


export default UniversalNav;
