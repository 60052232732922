import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  selectedOrg: JSON.parse(localStorage.getItem("selectedOrg")) || false,
  selectedSuspect: false,
};

export const organizationSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {
    setOrgData: (state, action) => {
      state.data = action.payload;
    },
    setSelectedOrg: (state, action) => {
      state.selectedOrg = action.payload;
      localStorage.setItem("selectedOrg", JSON.stringify(action.payload));
    },
  },
});

// Action creators are generated for each case reducer function
export const { setOrgData, setSelectedOrg } = organizationSlice.actions;
export default organizationSlice.reducer;
