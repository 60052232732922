import React, { useEffect, useState, useCallback } from "react";
import { Navbar, NavbarBrand } from "reactstrap";
import "./NavMenu.css";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { activeAccount, signIn, signOut } from "../../utils/authPopup.js";
import SGLogoTM from "../../images/SGLogoTM.png";
import SGLogo from "../../images/SGLogo.png";
import { useNavigate } from "react-router-dom";
import NewOrg from "../../components/NewOrg";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedOrg } from "../../redux/slices/organization";
import ThemeButton from "../../components/ThemeButton.js";

function NavMenu({ superAdminShow }) {
  const [orgDropItems, setOrgDropItems] = useState([]);
  const dispatch = useDispatch();
  const orgData = useSelector((state) => state.organization.data);
  const selectedOrg = useSelector((state) => state.organization.selectedOrg);

  const navigate = useNavigate();

  const { instance } = useMsal();

  const [showModal, setShowModal] = useState(false);

  const handleOrgChange = useCallback(
    (e) => {
      e.preventDefault();
      const newOrg = orgData.filter((data) => data.id === e.target.id);
      dispatch(setSelectedOrg(newOrg[0]));
    },
    [orgData, dispatch]
  );

  useEffect(() => {
    const orgItems = orgData.map((data) => {
      return data.id !== selectedOrg.id ? (
        <Dropdown.Item
          key={data.id}
          className="dropItem"
          as="button"
          onClick={handleOrgChange}
          name={data.name}
          id={data.id}
        >
          {data.name}
        </Dropdown.Item>
      ) : null;
    });

    setOrgDropItems(orgItems);
  }, [handleOrgChange, orgData, selectedOrg.id]);

  function handleLogin(e) {
    signIn(e, instance);
    navigate("/");
  }

  function navHome() {
    navigate("/");
  }
  function handleHelpPage() {
    navigate("/help");
  }

  return (
    <header className="head">
      <Navbar
        className="navbar-toggleable-sm ng-white border-bottom box-shadow mb-3 "
        light
      >
        <NavbarBrand className="d-flex align-items-center user-text">
          {activeAccount ? (
            <>
              <img
                src={SGLogo}
                className="sg-logo"
                height="40"
                alt="Street Grace Logo"
              />
              <span onClick={navHome}>Welcome, {activeAccount.name}</span>
              <div className="hi ms-3">
                <DropdownButton title={selectedOrg.name}>
                  {orgDropItems}
                  {superAdminShow && (
                    <Dropdown.Item
                      className="dropItem"
                      onClick={() => setShowModal(true)}
                    >
                      + Add New
                    </Dropdown.Item>
                  )}
                </DropdownButton>
              </div>
            </>
          ) : (
            <img
              src={SGLogoTM}
              className="sg-logo"
              height="40"
              alt="Street Grace Logo TM"
            />
          )}
        </NavbarBrand>
        {showModal ? (
          <NewOrg
            show={showModal}
            instance={instance}
            openModal={() => setShowModal(true)}
            closeModal={() => setShowModal(false)}
          />
        ) : null}

        <ul className="rightContainer">
          <AuthenticatedTemplate>
            <ThemeButton />
            <a
              className="login"
              href="https://www.streetgrace.org/ti-faq"
              target="_blank"
            >
              Help
            </a>
            <p
              className="login"
              title="Sign Out"
              onClick={(e) => signOut(e, instance)}
            >
              Sign Out
            </p>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <p className="login" title="Sign In" onClick={handleLogin}>
                Sign In
              </p>
            </div>
          </UnauthenticatedTemplate>
        </ul>
      </Navbar>
    </header>
  );
}

export default NavMenu;
