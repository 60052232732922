import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import InfoCard from "../../components/shared/InfoCard/InfoCard";
import SuspectNotes from "../../components/Suspects/SuspectNotes";
import { useSelector } from "react-redux";

import { StyledCol } from "../../components/shared/InfoCard/InfoCard.styled";

const SuspectProfile = ({ instance }) => {
  const selectedSuspect = useSelector((state) => state.suspect.selectedSuspect);

  function formatLearnedInfo(info) {
    // TODO: prioritize info such as name first
    info = {
      Name: isNaN(info.name) ? info.name : info.formattedPhoneNumber,
      Operation: info.operation,
      "Phone Number": info.formattedPhoneNumber,
      Risk: info.suspectRiskLevel,
      "Age Range": info.ageRange,
      "Total Bots Contacted": info.totalBotsContacted,
      "Total Messages": info.totalMessages,
      Carrier: info.carrier,
    };
    return Object.fromEntries(
      Object.entries(info).sort(([a], [b]) => a.localeCompare(b))
    );
  }

  const learnedInfo = formatLearnedInfo(selectedSuspect);

  const suspectRiskInfo = {
    "Risk Level": selectedSuspect.risk,
    "Organizations Shown Up In": 1,
    "Operations Show Up In": selectedSuspect.totalOperationCount,
  };

  const operationInfo = {
    "Operation Thunder": "11-12-2022",
    "Operation Ironclad": "12-12-2022",
    "Operation Storm": "01-12-2022",
  };

  const whitePagesInfo = {
    city: selectedSuspect.city ?? "No Value",
    operationListNames:
      selectedSuspect.totalOperations
        .map((operation) => operation.name)
        .join(", ") ?? "No Value",
    organizationLocations:
      selectedSuspect.totalOperations
        .map((operation) => operation.location)
        .join(", ") ?? "No Value",
    address: selectedSuspect.lastUpdatedCallerId?.address ?? "No Value",
    age: selectedSuspect.lastUpdatedCallerId?.age ?? "No Value",
    email: selectedSuspect.lastUpdatedCallerId?.email ?? "No Value",
    firstName: selectedSuspect.lastUpdatedCallerId?.firstName ?? "No Value",
    lastName: selectedSuspect.lastUpdatedCallerId?.lastName ?? "No Value",
    middleName: selectedSuspect.lastUpdatedCallerId?.middleName ?? "No Value",
    phoneNumber: selectedSuspect.lastUpdatedCallerId?.phoneNumber ?? "No Value",
    phoneType: selectedSuspect.lastUpdatedCallerId?.phoneType ?? "No Value",
    therapeuticMessageSent: selectedSuspect.therapeuticMessageSent
      ?.therapeuticMessageSent
      ? "Sent"
      : "Not Sent",
  };

  return (
    <div>
      <Container fluid>
        <Row>
          <Col>
            <h1>Suspect Details</h1>
          </Col>
          <Col />
          <Col xs="auto" style={{ display: "flex", alignItems: "flex-end" }}>
            <Link to="/suspects">Back to all &#8617;</Link>
          </Col>
        </Row>
        <Row>
          <StyledCol>
            <InfoCard title="Bot Learned Information" infoMap={learnedInfo} />
            <InfoCard
              title="White Pages Information"
              infoMap={whitePagesInfo}
            />
          </StyledCol>
          <StyledCol xs={6}>
            <SuspectNotes instance={instance} suspect={selectedSuspect} />
          </StyledCol>
          <StyledCol>
            <InfoCard title="Suspect Risk" infoMap={suspectRiskInfo} />
            <InfoCard title="Involvement" infoMap={operationInfo} />
          </StyledCol>
        </Row>
      </Container>
    </div>
  );
};

export default SuspectProfile;
