import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import SortIcon from "../../shared/SortIcon/SortIcon";
import { fetchWithAuth } from "../../utils/authPopup";
import "./PlatformData.css";

const Platformdata = ({ instance, id }) => {
  const [platformData, setPlatformData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOrgData = async () => {
      try {
        const response = await fetchWithAuth(
          instance,
          "GET",
          "api/platformdata"
        );
        console.log("Response:", response);

        let data;
        if (typeof response === "object" && response !== null) {
          data = response;
        } else if (response.json) {
          data = await response.json();
        } else {
          throw new Error("Unexpected response format");
        }

        setPlatformData(data);
      } catch (err) {
        setError(`Failed to fetch platform data: ${err.message}`);
      }
    };
    fetchOrgData();
  }, [instance]);

  if (error) {
    return <div>{error}</div>;
  }

  if (!platformData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="platform-data">
      <h2 style={{ marginBottom: "20px" }}>Platform Data</h2>
      <div className="stats-grid">
        <div className="stat-card">
          <h3>Total Bots</h3>
          <p>{platformData?.totalBots}</p>
        </div>
        <div className="stat-card">
          <h3>Total Messages Incoming to Bots</h3>
          <p>{platformData?.totalMessagesIncomingToBots}</p>
        </div>
        <div className="stat-card">
          <h3>Total Messages Outgoing to Suspects</h3>
          <p>{platformData?.totalMessagesOutgoingToSuspects}</p>
        </div>
        <div className="stat-card">
          <h3>Total Operations</h3>
          <p>{platformData?.totalOperations}</p>
        </div>
        <div className="stat-card">
          <h3>Total Repeat Suspects Found</h3>
          <p>{platformData?.totalRepeatSuspectsFound}</p>
        </div>
        <div className="stat-card">
          <h3>Total Suspects Found</h3>
          <p>{platformData?.totalSuspectsFound}</p>
        </div>
        <div className="stat-card">
          <h3>Total Therapeutic Messages Sent</h3>
          <p>{platformData?.totalTherapeuticMessagesSent}</p>
        </div>
        <div className="stat-card">
          <h3>Total Transactions Intercepted</h3>
          <p>{platformData?.totalTransactionsIntercepted}</p>
        </div>
      </div>
    </div>
  );
};

export default Platformdata;
