import styled from "styled-components";
import { Paper, Typography, Button } from "@mui/material";

export const ChatBubble = styled(Paper)`
  padding: 10px;
  margin: 5px;
  word-wrap: break-word;
  max-width: 50%;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  padding-right: 10px;
`;

export const IconContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-right: 10px;
`;

export const ChatContainer = styled(Paper)`
  background-color: ${(props) =>
    props.theme === "light" ? "#f4f3f8" : "#2a2f62"} !important;
  color: #f5f5f5 !important;
  height: 60vh;
  overflow: auto;
  margin-top: 20px;
  margin-right: 10px;
  width: calc(100vw - 850px);
`;

export const GroupMessagesContainer = styled.div`
  padding: 10px;
  overflow-y: scroll;
  height: calc(50vh - 70px);
`;

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(props) =>
    props.author === "OutgoingToSuspect" ? "flex-end" : "flex-start"};
  margin-bottom: 10px;
`;

export const DateText = styled(Typography)`
  display: block;
  margin-top: 8px;
  color: #f5f5f5;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TakeControlButton = styled(Button)`
  margin-left: 100px;
`;

export const CloseCaseButton = styled(Button)`
  border-color: #f5f5f5;
  color: #f5f5f5;
  margin-right: 100px;
`;

export const SendButton = styled(Button)`
  margin-left: 100px;
  margin-top: 30px;
`;

export const CloseButton = styled(Button)`
  border-color: #f5f5f5;
  color: #f5f5f5;
  margin-left: 50px;
`;
