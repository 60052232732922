import React, { useState, useEffect } from "react";
import ChatList from "./ChatList";
import ChatInfo from "./ChatInfo";
import { useSelector } from "react-redux";
import {
  ChatPageContainer,
  ChatListContainer,
  ChatPageWrapper,
  ChatInfoContainer,
  SideBarContainer,
} from "./ChatPage.styled";
import ChatMenu from "./ChatMenu";
import ChatPanel from "./ChatPanel";
import { fetchWithAuth } from "../../utils/authPopup.js";
import blur from "../../../src/images/chatpageaccessdenied.png";
import blur2 from "../../../src/images/chatpageNoData.png";

import { useDispatch } from "react-redux";

import {
  setSuspectData,
  setFormattedSuspectData,
} from "../../redux/slices/suspect.js";
import useGetOperations from "../../hooks/useGetOperations.jsx";
import useFetchChatConvos from "./hooks/useFetchChatConvos.jsx";
import useSignalR from "./hooks/useSignalR.jsx";
import convoSeen from "./utils/convoSeen.js";
const ChatPage = ({ instance, organizationId }) => {
  const theme = useSelector((state) => state.theme);
  const dispatch = useDispatch();

  const [error, setError] = useState(false);
  const [messageWasSent, setMessageWasSent] = useState(false);
  const [takeOverFilter, setTakeOverFilter] = useState(false);
  const [flagged, setFlaggedFilter] = useState(false);
  const [allConversation, setAllConversation] = useState(false);

  const { getOperations } = useGetOperations(instance);
  const {
    fetchChatConvos,
    refetchConvo,
    addConvo,
    conversations,
    setConversations,
    selectedChat,
    setSelectedChat,
    hasMoreConvos,
    selectedOperation,
    setSelectedOperation,
    selectedBot,
    setSelectedBot,
  } = useFetchChatConvos(instance, setError);

  const { newMessage } = useSignalR(
    instance,
    conversations,
    setConversations,
    addConvo
  );

  useEffect(() => {
    if (!selectedChat) return;

    convoSeen(instance, setConversations, organizationId, selectedChat);
  }, [newMessage]);

  async function extraCall(msalContext) {
    try {
      const data = await fetchWithAuth(
        msalContext,
        "GET",
        `api/organization/${organizationId}/suspects`
      );

      const parsedData = data.map((item, index) => {
        // Parse the last updated date/time into a more readable format based on locale
        const lastUpdatedDateTime = new Date(item.lastUpdatedDate);
        const lastUpdatedDate = lastUpdatedDateTime.toLocaleDateString();
        const lastUpdatedTime = lastUpdatedDateTime.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        });

        const operationList = item.operations.map((el) => el.name);
        const organizationLocations = item.operations.map((el) => {
          if (el.organizationId != organizationId) {
            return el.location;
          }
        });

        return {
          name: item.name,
          operationId: item.operations[0].id,
          operationListNames: operationList,
          formattedPhoneNumber: item.formattedPhoneNumber,
          organizationLocations: organizationLocations,
          operation: item.operations[0].name,
          risk: item.suspectRiskLevel,
          phoneNumber: item.phoneNumber,
          carrier: item.properties.Carrier,
          ageRange: item.properties.AgeRange,
          totalMessages: "TBD",
          id: item.id, // TODO: Use suspectId from fetched data. However, it is currently all 0s, which needs to be fixed
          totalBotsContacted: item.botIds.length,
          lastContactedDate: lastUpdatedDate,
          lastContactedTime: lastUpdatedTime,
          notes: item.notes,
          totalOperationCount: item.operationIds.length,
          totalOperations: item.operations,
          orgId: item.organizationId,
          city: item.whitePages?.address?.city,
          country: item.whitePages?.address?.country,
          state: item.whitePages?.address?.state,
          street: item.whitePages?.address?.street,
          unit: item.whitePages?.address?.unit,
          zipCode: item.whitePages?.address?.zipCode,
          age: item.whitePages?.age,
          email: item.whitePages?.email,
          firstName: item.whitePages?.firstName,
          lastName: item.whitePages?.lastName,
          lastReportedCallerIdInfo: item.whitePages?.lastReportedCallerIdInfo,
          lastUpdatedCallerId: item.whitePages?.lastUpdatedCallerId,
          lastUpdatedCriminalSearch: item.whitePages?.lastUpdatedCriminalSearch,
          middleName: item.whitePages?.middleName,
          phoneNumber: item.whitePages?.phoneNumber,
          phoneType: item.whitePages?.phoneType,
          therapeuticMessageSent: item.whitePages?.therapeuticMessageSent,
        };
      });

      dispatch(setFormattedSuspectData(parsedData));
      dispatch(setSuspectData(data));
    } catch (error) {
      if (error == "Forbidden") {
        setError(true);
      }
    }
  }

  useEffect(() => {
    getOperations();
    extraCall(instance);
  }, [organizationId, messageWasSent]);

  return (
    <div>
      {error ? (
        <img style={{ width: "98%", marginLeft: "10px" }} src={blur} />
      ) : conversations !== null ? (
        <ChatPageWrapper>
          <ChatPageContainer>
            <SideBarContainer style={{ marginLeft: "85px" }} theme={theme}>
              <ChatMenu
                setFlaggedFilter={setFlaggedFilter}
                setAllConversation={setAllConversation}
                setTakeOverFilter={setTakeOverFilter}
              />
            </SideBarContainer>
            <ChatListContainer>
              <ChatList
                flagged={flagged}
                takeOverFilter={takeOverFilter}
                organizationId={organizationId}
                allConversation={allConversation}
                instance={instance}
                conversations={conversations}
                setConversations={setConversations}
                fetchChatConvos={fetchChatConvos}
                hasMoreConvos={hasMoreConvos}
                selectedOperation={selectedOperation}
                setSelectedOperation={setSelectedOperation}
                selectedBot={selectedBot}
                setSelectedBot={setSelectedBot}
                selectedChat={selectedChat}
                setSelectedChat={setSelectedChat}
              />
            </ChatListContainer>
            <ChatListContainer>
              <ChatPanel
                setMessageWasSent={setMessageWasSent}
                instance={instance}
                organizationId={organizationId}
                conversations={conversations}
                setConversations={setConversations}
                selectedChat={selectedChat}
                refetchConvo={refetchConvo}
              />
            </ChatListContainer>
            <ChatInfoContainer>
              <ChatInfo
                selectedChat={selectedChat}
                key={selectedChat?.messages?.[0]?.date}
              />
            </ChatInfoContainer>
          </ChatPageContainer>
        </ChatPageWrapper>
      ) : (
        <img style={{ width: "98%", marginLeft: "10px" }} src={blur2} />
      )}
    </div>
  );
};

export default ChatPage;
