import { useState, useEffect, useRef } from "react";
import * as signalR from "@microsoft/signalr";
import { fetchWithAuth } from "../../../utils/authPopup";

const useSignalR = (instance, conversations, setConversations, addConvo) => {
  const [connection, setConnection] = useState(null);
  const [newMessage, setNewMessage] = useState(false);
  const conversationsRef = useRef(conversations);

  useEffect(() => {
    conversationsRef.current = conversations;
  }, [conversations]);

  useEffect(() => {
    const start = async () => {
      try {
        const { url, accessToken } = await fetchWithAuth(
          instance,
          "GET",
          "api/negotiate"
        );

        const con = new signalR.HubConnectionBuilder()
          .withUrl(url, { accessTokenFactory: () => accessToken })
          .build();

        await con.start();
        console.log("SignalR Connected.");

        setConnection(con);
      } catch (err) {
        console.log(err);
      }
    };

    start();

    return async () => {
      try {
        await connection?.stop();
        console.log("SignalR Disconnected.");
      } catch (err) {
        console.log(err);
      }
    };
  }, []);

  useEffect(() => {
    const addReceiveMessageListener = () => {
      connection?.on("newMessages", (newMessages) => {
        setNewMessage((prev) => !prev);
        const match = conversationsRef.current.find(
          (conversation) => conversation.id === newMessages.id
        );

        if (!match) {
          addConvo(newMessages.id);
          return;
        }

        setConversations((prevConversations) =>
          prevConversations.map((conversation) =>
            conversation.id === newMessages.id
              ? {
                  ...conversation,
                  messages: newMessages.messages,
                  unreadMessages: newMessages.unreadMessages,
                }
              : conversation
          )
        );
      });
    };

    const addBotMessageListener = () => {
      connection?.on("botMessage", (botMessage) => {
        setConversations((prevConversations) =>
          prevConversations.map((conversation) =>
            conversation.id === botMessage.id
              ? {
                  ...conversation,
                  messages: [
                    ...conversation.messages,
                    {
                      direction: "OutgoingToSuspect",
                      text: botMessage.message,
                      sentTime: botMessage.sentTime,
                    },
                  ],
                }
              : conversation
          )
        );
      });
    };

    if (connection) {
      addReceiveMessageListener();
      addBotMessageListener();
    }
  }, [connection]);

  return { newMessage };
};

export default useSignalR;
