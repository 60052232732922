import styled from "styled-components";

export const ChatInfoContainer = styled.div`
  background-color: ${(props) =>
    props.theme === "light" ? "#f4f3f8" : "#2a2f62"};
  display: flex;
  justify-content: flex-start;
  padding: 2px;
  border-radius: 10px;
  margin-left: 15px;
  margin-top: 50px;
  margin-right: 4rem;
  height: calc(100vh - 225px);
  flex-direction: column;
  overflow: auto;
`;

export const ChatInfoHeader = styled.div`
  justify-content: flex-start;
  color: #aaaaaa;
  padding-left: 10px;
  font-size: 1em;
  padding-top: 5px;
`;

export const ChatInfoListGroupContainer = styled.div`
  background-color: #2a2f62;
  width: 100%;
  margin: 5px;
  align-items: top;
  display: flex;
  flex-direction: column;
`;

export const ChatInfoGroupContainer = styled.div`
  background-color: 1C1F3C;
`;

export const ChatInfoItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px; /* Adjust the value to control the spacing between items */
  padding: 5px;
  margin-left: 5px;
`;

export const ChatInfoLabel = styled.span`
  font-size: 0.8em;
`;

export const ChatNotesContainer = styled.div`
  background-color: ${(props) =>
    props.theme === "light" ? "#C3C2C6" : "#0A0E37"};
  margin: 20px;
  padding: 10px;
  width: 80%;
  display: center;
  height: 100px;
  border-radius: 5px;
`;

export const ChatInfoBadge = styled.div`
  background-color: #2a2f62;
`;
