import React, { useState, useEffect } from "react";
import Badge from "react-bootstrap/Badge";
import { useSelector } from "react-redux";
import {
  ChatInfoContainer,
  ChatInfoHeader,
  ChatInfoGroupContainer,
  ChatInfoItem,
  ChatInfoLabel,
  ChatNotesContainer,
} from "./ChatInfo.styled.js";
import Card from "react-bootstrap/Card";
import { formatDate } from "./utils/messageFormat.js";

const ChatInfo = ({ selectedChat }) => {
  const theme = useSelector((state) => state.theme);

  const [chatInfo, setChatInfo] = useState({
    status: selectedChat?.status,
    suspectName: selectedChat?.suspectName,
    suspectPhoneNumber: selectedChat?.suspectPhoneNumber,
    currentMode: "Bot Controlled",
    agentAssigned: "Mark",
    totalMessages: selectedChat?.length || "Unavailable",
    firstMessage: selectedChat?.dateTime || "Unavailable",
    name: selectedChat?.name || "Unavailable",
    botName: selectedChat?.botName || "Unavailable",
    phoneNumber: "555-555-5555",
    age: "45",
    firstMessageDate: selectedChat?.firstMessageDate,
    region: "North America",
    carrier: "AT&T",
    address: "1234 Main St. Anytown, USA",
    person: {
      city: selectedChat?.suspect?.whitePages?.address?.city,
      country: selectedChat?.suspect?.whitePages?.address?.country,
      state: selectedChat?.suspect?.whitePages?.address?.state,
      street: selectedChat?.suspect?.whitePages?.address?.street,
      unit: selectedChat?.suspect?.whitePages?.address?.unit,
      zipCode: selectedChat?.suspect?.whitePages?.address?.zipCode,
      age: selectedChat?.suspect?.whitePages?.age,
      email: selectedChat?.suspect?.whitePages?.email,
      firstName: selectedChat?.suspect?.whitePages?.firstName,
      lastName: selectedChat?.suspect?.whitePages?.lastName,
      lastReportedCallerIdInfo:
        selectedChat?.suspect?.whitePages?.lastReportedCallerIdInfo,
      lastUpdatedCallerId:
        selectedChat?.suspect?.whitePages?.lastUpdatedCallerId,
      lastUpdatedCriminalSearch:
        selectedChat?.suspect?.whitePages?.lastUpdatedCriminalSearch,
      middleName: selectedChat?.suspect?.whitePages?.middleName,
      phoneNumber: selectedChat?.suspect?.whitePages?.phoneNumber,
      phoneType: selectedChat?.suspect?.whitePages?.phoneType,
      therapeuticMessageSent:
        selectedChat?.suspect?.whitePages?.therapeuticMessageSent,
    },
  });
  useEffect(() => {
    setChatInfo({
      ...chatInfo,
      status: selectedChat?.status || "Unavailable",
      suspectPhoneNumber: selectedChat?.suspectPhoneNumber,

      name: selectedChat?.name || "Unavailable",
      firstMessage: selectedChat?.dateTime || "Unavailable",
      botName: selectedChat?.botName || "Unavailable",
      totalMessages: selectedChat?.length || "Unavailable",
      firstMessageDate: selectedChat?.firstMessageDate || "Unavailable",
      takeoverTime: selectedChat?.takeoverTime || "Unavailable",
      takeoverUserName: selectedChat?.takeoverUserName || "Unavailable",
      person: {
        city: selectedChat?.suspect?.whitePages?.address?.city,
        country: selectedChat?.suspect?.whitePages?.address?.country,
        state: selectedChat?.suspect?.whitePages?.address?.state,
        street: selectedChat?.suspect?.whitePages?.address?.street,
        unit: selectedChat?.suspect?.whitePages?.address?.unit,
        zipCode: selectedChat?.suspect?.whitePages?.address?.zipCode,
        age: selectedChat?.suspect?.whitePages?.age,
        email: selectedChat?.suspect?.whitePages?.email,
        firstName: selectedChat?.suspect?.whitePages?.firstName,
        lastName: selectedChat?.suspect?.whitePages?.lastName,
        lastReportedCallerIdInfo:
          selectedChat?.suspect?.whitePages?.lastReportedCallerIdInfo,
        lastUpdatedCallerId:
          selectedChat?.suspect?.whitePages?.lastUpdatedCallerId,
        lastUpdatedCriminalSearch:
          selectedChat?.suspect?.whitePages?.lastUpdatedCriminalSearch,
        middleName: selectedChat?.suspect?.whitePages?.middleName,
        phoneNumber: selectedChat?.suspect?.whitePages?.phoneNumber,
        phoneType: selectedChat?.suspect?.whitePages?.phoneType,
        therapeuticMessageSent:
          selectedChat?.suspect?.whitePages?.therapeuticMessageSent,
      },
    });
  }, [selectedChat]);

  const handleTime = (time) => {
    let timeTime =
      time == null ? "No Data" : new Date(time).toLocaleTimeString();
    return timeTime;
  };
  const handleDate = (time) => {
    if (time == null) {
      return "No Data";
    } else {
      const date = new Date(time);
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const month = monthNames[date.getMonth()];
      const day = date.getDate();
      const year = date.getFullYear();
      return `${month} ${day}, ${year}`;
    }
  };
  return (
    <>
      <div style={{ position: "absolute", marginLeft: "15px" }}>
        {/* <PrimaryButton className="me-3 absolute">
          <Icon icon="material-symbols:download" color="#fff" /> Export
        </PrimaryButton> */}
      </div>
      <ChatInfoContainer style={{ width: "18rem" }} theme={theme}>
        <ChatInfoHeader>Conversation Info</ChatInfoHeader>
        <ChatInfoGroupContainer variant="flush">
          <ChatInfoItem>
            <ChatInfoLabel>Status: {chatInfo.status}</ChatInfoLabel>
            {/* <div>
              <ChatInfoLabel>
                Agent assigned: {chatInfo.agentAssigned}
              </ChatInfoLabel>
            </div> */}
            <ChatInfoLabel>
              Total Messages: {chatInfo.totalMessages}
            </ChatInfoLabel>
            <ChatInfoLabel>
              First Message: {handleTime(chatInfo.firstMessageDate)} /{" "}
              {handleDate(chatInfo.firstMessageDate)}
            </ChatInfoLabel>
            {chatInfo.status === "Takeover" ? (
              <>
                <ChatInfoLabel>
                  Takeover: {formatDate(chatInfo.takeoverTime)}
                </ChatInfoLabel>
                <ChatInfoLabel>
                  Takeover By: {chatInfo.takeoverUserName}
                </ChatInfoLabel>
              </>
            ) : null}
          </ChatInfoItem>
          <hr style={{ margin: "15px 0", height: "1px" }} />
          <ChatInfoHeader>Bot learned info</ChatInfoHeader>
          <ChatInfoItem>Coming Soon</ChatInfoItem>
          <hr style={{ margin: "15px 0", height: "1px" }} />
          <ChatInfoHeader>White pages info</ChatInfoHeader>
          <ChatInfoItem>
            <div>
              <ChatInfoLabel>
                Name: {chatInfo?.person?.firstName}{" "}
                {chatInfo?.person?.middleName} {chatInfo?.person?.lastName}
              </ChatInfoLabel>
            </div>
            <div>
              <ChatInfoLabel>
                Phone Number: {chatInfo.suspectPhoneNumber}
              </ChatInfoLabel>
            </div>
            <div>
              <ChatInfoLabel>Age: {chatInfo.person.age}</ChatInfoLabel>
            </div>
            <div>
              <ChatInfoLabel>
                Email: {chatInfo.person.email || "N/A"}
              </ChatInfoLabel>
            </div>
            <div>
              <ChatInfoLabel>
                Address: {chatInfo.person.street},{" "}
                {chatInfo.person.unit ? chatInfo.person.unit + ", " : ""}
                {chatInfo.person.city}, {chatInfo.person.state}{" "}
                {chatInfo.person.zipCode}, {chatInfo.person.country}
              </ChatInfoLabel>
            </div>
            <div>
              <ChatInfoLabel>
                Phone Type: {chatInfo.person.phoneType}
              </ChatInfoLabel>
            </div>
            <div>
              <ChatInfoLabel>
                Last Reported Caller ID Info:{" "}
                {chatInfo.person.lastReportedCallerIdInfo}
              </ChatInfoLabel>
            </div>
            <div>
              <ChatInfoLabel>
                Last Updated Caller ID: {chatInfo.person.lastUpdatedCallerId}
              </ChatInfoLabel>
            </div>
            <div>
              <ChatInfoLabel>
                Therapeutic Message Sent:{" "}
                {chatInfo.person.therapeuticMessageSent ? "Yes" : "No"}
              </ChatInfoLabel>
            </div>
          </ChatInfoItem>
          <hr style={{ margin: "15px 0", height: "1px" }} />
          <ChatInfoHeader>Suspect Note:</ChatInfoHeader>
          <ChatInfoItem>
            <ChatNotesContainer theme={theme}>
              <Card.Text>...</Card.Text>
            </ChatNotesContainer>
          </ChatInfoItem>
          <hr style={{ margin: "15px 0", height: "1px" }} />
          <ChatInfoHeader>Tags</ChatInfoHeader>
          <Badge pill bg="secondary" style={{ margin: "15px 0 10px 10px" }}>
            + Add
          </Badge>
          <hr style={{ margin: "15px 0", height: "1px" }} />
          <ChatInfoHeader>...</ChatInfoHeader>
          <ChatInfoItem></ChatInfoItem>
        </ChatInfoGroupContainer>
      </ChatInfoContainer>
    </>
  );
};

export default ChatInfo;
