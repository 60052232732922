import styled from "styled-components";

export const ChatListContainer = styled.div`
    background-color: ${(props) =>
      props.theme === "light" ? "#f4f3f8" : "#2a2f62"};
    display: flex;
    justify-content: flex-start;
    text-align: left;
    padding: 2px;
    width: "calc(100vw - 600px)"
    border-radius: 10px;
    margin-left: 10px;
    margin-right: 2rem;
    margin-top: 50px;
    height: calc(100vh - 230px);
    overflow: scroll;
    flex-direction: column;
    border-radius: 10px;
`;

export const ListGroupContainer = styled.div`
  background-color: ${(props) =>
    props.theme === "light" ? "#f4f3f8" : "#2a2f62"};
  width: 100%;
  margin: 2px;
  align-items: top;
`;

export const ListItemContainer = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 10px;
`;

export const HeaderContainer = styled.div`
  background-color: ${(props) =>
    props.theme === "light" ? "#f4f3f8" : "#2a2f62"};
  display: flex;
  align-items: center;
  padding: 10px;
`;

export const AllLabel = styled.span`
  font-size: 1em;
  font-weight: bold;
  margin-right: 10px;
`;

export const SearchBox = styled.input.attrs({ type: "text" })`
  background-color: ${(props) =>
    props.theme === "light" ? "#f4f3f8" : "#2a2f62"};
  padding: 5px 30px 5px 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  flex-grow: 1;
  width: 80%;
  position: relative;
  font-size: 1em;
`;
