import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { Icon } from "@iconify/react";
import styled from "styled-components";
import { useSelector } from "react-redux";

const CustomButton = styled(Button)`
  border: none;
  margin-bottom: 10px;
  padding-top: 15px;
`;

const ChatMenu = ({
  setTakeOverFilter,
  setFlaggedFilter,
  setAllConversation,
}) => {
  const theme = useSelector((state) => state.theme);

  const icons = [
    { icon: "mdi:email-outline", label: "All conversations" },
    { icon: "mdi:email-open-multiple-outline", label: "Multiple Emails" },
    { icon: "bxs:label", label: "Flagged Conversations" },
    { icon: "mdi:email-lock-outline", label: "Taken Over Conversations" },
  ];

  const [activeButton, setActiveButton] = useState(null);

  const handleGroup = (icon, index) => {
    if (icon === "mdi:email-lock-outline") {
      setTakeOverFilter((prev) => !prev);
      setFlaggedFilter(false);
      setAllConversation(false);
    } else if (icon === "bxs:label") {
      setFlaggedFilter((prev) => !prev);
      setTakeOverFilter(false);
      setAllConversation(false);
    } else if (icon === "mdi:email-outline") {
      setAllConversation((prev) => !prev);
      setTakeOverFilter(false);
      setFlaggedFilter(false);
    }

    setActiveButton(activeButton === index ? null : index);
  };

  return (
    <ButtonGroup vertical>
      {icons.map((button, index) => {
        let itemColor;
        if (theme === "light") {
          itemColor = activeButton === index ? "#C3C2C6" : "#F4F3F8";
        } else if (theme === "dark") {
          itemColor = activeButton === index ? "#1C1F3C" : "#2A2F62";
        } else {
          itemColor = "#2A2F62";
        }

        return (
          <CustomButton
            key={button.icon}
            style={{
              backgroundColor: itemColor,
            }}
            onClick={() => handleGroup(button.icon, index)}
            title={button.label}
          >
            <Icon
              icon={button.icon}
              color={theme === "light" ? "#2A2F62" : "#F4F3F8"}
            />
          </CustomButton>
        );
      })}
    </ButtonGroup>
  );
};

export default ChatMenu;
