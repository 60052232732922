import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import {
  STTableContainer,
  STTable,
  STThead,
  STTbody,
  STTr,
  STTh,
  STTd,
} from "./SuspectsTable.styled";
import SortIcon from "../../shared/SortIcon/SortIcon";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedSuspect } from "../../redux/slices/suspect";

export const ST = ({ children, ...rest }) => {
  return <STTableContainer {...rest}>{children}</STTableContainer>;
};

ST.Table = ({ children, ...rest }) => {
  return <STTable {...rest}>{children}</STTable>;
};

ST.Head = ({ children, ...rest }) => {
  return <STThead {...rest}>{children}</STThead>;
};

ST.Body = ({ children, ...rest }) => {
  return <STTbody {...rest}>{children}</STTbody>;
};

ST.Tr = ({ children, ...rest }) => {
  return <STTr {...rest}>{children}</STTr>;
};

ST.Th = ({ children, ...rest }) => {
  return <STTh {...rest}>{children}</STTh>;
};

ST.Td = ({ children, ...rest }) => {
  return <STTd {...rest}>{children}</STTd>;
};

const SuspectsTable = ({ suspects = [] }) => {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme);

  const [sortField, setSortField] = useState("lastContacted");
  const [sortOrder, setSortOrder] = useState("desc");
  const [items, setItems] = useState(suspects);

  const formattedSuspectData = useSelector(
    (state) => state.suspect.formattedData
  );

  // Update the "items" state when "suspects" prop changes
  useEffect(() => {
    setItems(suspects);
  }, [suspects]);

  // Sort the items array based on the current sort field and order whenever they change
  const sortedItems = items.slice().sort((a, b) => {
    const order = sortOrder === "asc" ? 1 : -1;
    return order * (a[sortField] > b[sortField] ? 1 : -1);
  });

  const currentItems = sortedItems;

  // Set the sort order based on the field that was chosen
  const handleSort = (field) => {
    if (sortField === field) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortOrder("asc");
    }
  };

  // Change the page number in the table
  // const handlePageChange = (pageNumber) => {
  //   setCurrentPage(pageNumber);
  // };

  function setSuspect(e) {
    const suspect = formattedSuspectData.filter(
      (suspect) => suspect.id === e.target.id
    );
    dispatch(setSelectedSuspect(suspect[0]));
  }

  return (
    <ST theme={theme}>
      <ST.Table>
        <ST.Head>
          <ST.Tr>
            <ST.Th onClick={() => handleSort("name")}>
              Name
              <SortIcon
                sortField={"name"}
                currField={sortField}
                sortOrder={sortOrder}
              />
            </ST.Th>
            {/* <ST.Th onClick={() => handleSort("operation")}>
              Operation
              <SortIcon
                sortField={"operation"}
                currField={sortField}
                sortOrder={sortOrder}
              />
            </ST.Th> */}
            <ST.Th onClick={() => handleSort("lastContacted")}>
              Last Contacted
              <SortIcon
                sortField={"lastContacted"}
                currField={sortField}
                sortOrder={sortOrder}
              />
            </ST.Th>
            <ST.Th onClick={() => handleSort("risk")}>
              Risk
              <SortIcon
                sortField={"risk"}
                currField={sortField}
                sortOrder={sortOrder}
              />
            </ST.Th>
            <ST.Th onClick={() => handleSort("phoneNumber")}>
              Phone Number
              <SortIcon
                sortField={"phoneNumber"}
                currField={sortField}
                sortOrder={sortOrder}
              />
            </ST.Th>
            <ST.Th onClick={() => handleSort("ageRange")}>
              Age Range
              <SortIcon
                sortField={"ageRange"}
                currField={sortField}
                sortOrder={sortOrder}
              />
            </ST.Th>
            <ST.Th onClick={() => handleSort("totalMessages")}>
              Total Messages
              <SortIcon
                sortField={"totalMessages"}
                currField={sortField}
                sortOrder={sortOrder}
              />
            </ST.Th>
            <ST.Th onClick={() => handleSort("totalBotsContacted")}>
              Total Bots Contacted
              <SortIcon
                sortField={"totalBotsContacted"}
                currField={sortField}
                sortOrder={sortOrder}
              />
            </ST.Th>
          </ST.Tr>
          <br />
        </ST.Head>
        <ST.Body>
          {currentItems.map((suspect) => (
            <>
              <ST.Tr key={suspect.phoneNumber}>
                <ST.Td>
                  <Link
                    onClick={setSuspect}
                    id={suspect.id}
                    to={`suspect-profile/${suspect.id}`}
                    style={{ color: theme === "light" ? "inherit" : "#FFFFFF" }}
                  >
                    {isNaN(suspect.name)
                      ? suspect.name
                      : suspect.formattedPhoneNumber}
                  </Link>
                </ST.Td>
                {/* <ST.Td>
                  <button
                    style={{
                      color: "#FFFFFF",
                      fontSize: "20px",
                      backgroundColor: "transparent",
                      border: "none",
                      textDecoration: "underline",
                    }}
                    onClick={() => {
                      navigate(`/operations/operation/${suspect.operationId}`);
                    }}
                  >
                    {suspect.operation}
                  </button>
                </ST.Td> */}
                <ST.Td>
                  {suspect.lastContactedDate + " " + suspect.lastContactedTime}
                </ST.Td>
                <ST.Td>
                  {suspect?.suspectRiskLevel === "High Risk" ? (
                    <Icon
                      className="general-icon"
                      icon="fluent-emoji-flat:red-circle"
                    />
                  ) : suspect?.suspectRiskLevel === "Medium Risk" ? (
                    <Icon
                      className="general-icon"
                      icon="fluent-emoji-flat:orange-circle"
                    />
                  ) : (
                    <Icon
                      className="general-icon"
                      icon="fluent-emoji-flat:yellow-circle"
                    />
                  )}
                  &nbsp;{suspect?.suspectRiskLevel}
                </ST.Td>
                <ST.Td>{suspect.formattedPhoneNumber}</ST.Td>
                <ST.Td>{suspect.ageRange}</ST.Td>
                <ST.Td>{suspect.totalMessages}</ST.Td>
                <ST.Td>{suspect.totalBotsContacted}</ST.Td>
              </ST.Tr>
            </>
          ))}
        </ST.Body>
      </ST.Table>
    </ST>
  );
};

export default SuspectsTable;
