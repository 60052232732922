import React, { useState, useEffect, useRef } from "react";
import { Typography } from "@mui/material";
import { fetchWithAuth } from "../../utils/authPopup.js";
import { Icon } from "@iconify/react";
import {
  ChatBubble,
  HeaderContainer,
  TakeControlButton,
  ButtonContainer,
  DateText,
  MessageContainer,
  GroupMessagesContainer,
  ChatContainer,
  SendButton,
  CloseButton,
} from "./ChatPanel.styled";
import { useNavigate } from "react-router-dom";
import { setSelectedSuspect } from "../../redux/slices/suspect";
import { useDispatch, useSelector } from "react-redux";
import styles from "./ChatPanel.module.css";
import { formatDate, messageAuthor } from "./utils/messageFormat.js";
import handleError from "../../utils/handleError.js";

const ChatPanel = ({
  conversations,
  selectedChat,
  organizationId,
  instance,
  setMessageWasSent,
  refetchConvo,
}) => {
  const theme = useSelector((state) => state.theme);

  const [chatMessages, setChatMessages] = useState([]);
  const [showImage, setShowImage] = useState(false);
  const [clickedImageUrl, setClickedImageUrl] = useState(null);
  const [showMessageBox, setShowMessageBox] = useState(false);
  const [messageValue, setMessageValue] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewSrc, setPreviewSrc] = useState(null);
  const fileInputRef = useRef(null);

  const navigate = useNavigate();
  const formattedSuspectData = useSelector(
    (state) => state.suspect.formattedData
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedChat?.id) {
      const conversation = conversations.find(
        (convo) => convo.id === selectedChat.id
      );
      setChatMessages(conversation?.messages);
    }
  }, [conversations, selectedChat]);

  useEffect(() => {
    setShowMessageBox(selectedChat?.humanTakeover);
  }, [conversations, selectedChat]);

  const handleTakeOver = async () => {
    if (!selectedChat?.id) return alert("pick a conversation first!");

    await fetchWithAuth(
      instance,
      "POST",
      `api/organization/${organizationId}/conversations/${selectedChat?.id}/human-takeover`
    );

    await refetchConvo(selectedChat?.id);

    setShowMessageBox(true);
  };

  const setSuspect = (e) => {
    const suspect = formattedSuspectData.filter(
      (suspect) => suspect.id === selectedChat?.suspect.id
    );
    dispatch(setSelectedSuspect(suspect[0]));
    navigate(`/suspects/suspect-profile/${selectedChat?.suspect.id}`);
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
    setPreviewSrc(URL.createObjectURL(e.target.files[0]));
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
    setPreviewSrc(null);
    fileInputRef.current.value = null;
  };

  const handleSendMessage = async () => {
    try {
      const message = { messageText: messageValue, fileUrl: "" };

      if (selectedFile) {
        const formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("operationId", selectedChat?.operationId);

        const media = await fetchWithAuth(
          instance,
          "POST",
          `api/organization/${organizationId}/media/upload`,
          formData
        );

        message.fileUrl = media.url;
      }

      handleRemoveFile();

      await fetchWithAuth(
        instance,
        "POST",
        `api/organization/${organizationId}/conversations/${selectedChat?.id}/send-message`,
        message
      );

      await refetchConvo(selectedChat?.id);

      setMessageValue("");
      setMessageWasSent((prev) => !prev);
    } catch (error) {
      handleError(error);
    }
  };

  const handleImageClick = (element) => {
    setClickedImageUrl(element);
    setShowImage(true);
  };

  const handleImageClose = (element) => {
    setClickedImageUrl("");
    setShowImage(false);
  };

  function createImageElementFromUrl(item) {
    if (item.images?.length > 0) {
      return item.images?.map((element, index) => (
        <div key={index}>
          {clickedImageUrl !== element && (
            <>
              <img
                src={element}
                alt="Image"
                style={{
                  width: "100%",
                  height: "auto",
                  filter: "blur(60px)",
                }}
              />
              <button
                style={{
                  backgroundColor: "#2a2f62",
                  color: "white",
                  fontSize: "1.3rem",
                  marginTop: "3px",
                  padding: "8px 16px",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
                onClick={() => handleImageClick(element)}
              >
                click to show
              </button>
            </>
          )}
          {clickedImageUrl === element && showImage && (
            <>
              <img
                src={element}
                alt="Image"
                style={{
                  width: "100%",
                  height: "auto",
                  filter: "none",
                }}
              />
              <button
                onClick={() => handleImageClose(element)}
                style={{
                  backgroundColor: "#2a2f62",
                  marginTop: "3px",
                  color: "white",
                  fontSize: "1.3rem",
                  padding: "8px 16px",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
              >
                click to close
              </button>
            </>
          )}
        </div>
      ));
    } else {
      return <Typography>{item.text}</Typography>;
    }
  }

  const containerRef = useRef(null);
  useEffect(() => {
    const scrollToBottom = () => {
      if (containerRef.current) {
        const scrollHeight = containerRef.current.scrollHeight;
        const offsetHeight = containerRef.current.offsetHeight;
        containerRef.current.scrollTop = scrollHeight - offsetHeight;
      }
    };
    setTimeout(scrollToBottom, 0);
  }, [selectedChat]);

  return (
    <>
      <HeaderContainer>
        <Typography>
          Suspect Name:
          <span
            onClick={setSuspect}
            style={{
              marginLeft: "2px",
              color: "#5356FF",
              cursor: "pointer",
              textDecoration: "underline",
              textDecorationThickness: "2px",
              textUnderlineOffset: "4px",
              fontStyle: "italic",
            }}
          >
            {selectedChat?.suspectName
              ? selectedChat?.suspectName
              : selectedChat?.suspectPhoneNumber}
          </span>
        </Typography>
      </HeaderContainer>
      <ChatContainer elevation={3} theme={theme}>
        <GroupMessagesContainer
          style={{
            height: "100%",
            backgroundColor: selectedChat?.humanTakeover
              ? "#451d2b"
              : "initial",
          }}
          ref={containerRef}
        >
          {chatMessages?.map((message, index) => (
            <MessageContainer key={index} author={message.direction}>
              <ChatBubble
                style={{
                  backgroundColor:
                    message.direction === "IncomingToBot"
                      ? "#46A6FF"
                      : "#F5F5F5",
                  color:
                    message.direction === "OutgoingToSuspect"
                      ? "#02041A"
                      : "#000000",
                }}
              >
                {createImageElementFromUrl(message, index)}
              </ChatBubble>
              <DateText variant="caption">
                {`Sent by ${messageAuthor(
                  selectedChat.suspectName,
                  message.direction,
                  message.sentByUserName
                )} at ${formatDate(message.sentTime)}`}
              </DateText>
            </MessageContainer>
          ))}
        </GroupMessagesContainer>
      </ChatContainer>
      <hr style={{ height: "2px", marginTop: "20px" }} />
      <ButtonContainer>
        {!showMessageBox && (
          <>
            <TakeControlButton
              onClick={handleTakeOver}
              variant="contained"
              color="primary"
              size="large"
            >
              Take Control
            </TakeControlButton>
          </>
        )}

        {showMessageBox && (
          <div>
            <div className={styles.messageBoxInput}>
              <textarea
                value={messageValue}
                onChange={(e) => setMessageValue(e.target.value)}
                placeholder="Type your message..."
                style={{
                  backgroundColor: theme === "light" ? "#f4f3f8" : "#2a2f62",
                  color: theme === "light" ? "#000000" : "#ffffff",
                }}
              ></textarea>
              <div className={styles.fileUpload}>
                <div className={styles.fileContainer}>
                  <Icon
                    icon="ic:sharp-upload-file"
                    fontSize={26}
                    color={theme === "light" ? "#000000" : "#ffffff"}
                  />
                  <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                  />
                </div>
              </div>
              {previewSrc && (
                <img
                  src={previewSrc}
                  alt="preview"
                  className={styles.previewImg}
                  onClick={handleRemoveFile}
                />
              )}
            </div>
            <ButtonContainer>
              <SendButton
                onClick={handleSendMessage}
                variant="contained"
                color="primary"
                size="large"
              >
                Send
              </SendButton>
              {selectedChat.humanTakeover ? (
                <></>
              ) : (
                <CloseButton
                  onClick={() => setShowMessageBox(false)}
                  variant="outlined"
                  size="large"
                >
                  Close Case
                </CloseButton>
              )}
            </ButtonContainer>
          </div>
        )}
      </ButtonContainer>
    </>
  );
};

export default ChatPanel;
