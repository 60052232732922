import React from "react";
import map from "../images/map.png";

function Welcome() {
  return (
    <div>
      <h1 className="welcome-message">Welcome to the Gracie Platform!</h1>
      <img className="map" src={map} />
    </div>
  );
}

export default Welcome;
