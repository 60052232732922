import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { fetchWithAuth } from "../../utils/authPopup";
import { useDispatch, useSelector } from "react-redux";
import { setOrgData, setSelectedOrg } from "../../redux/slices/organization";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import NewUser from "../../components/NewUser";
import useChangeOrgData from "./hooks/useChangeOrgData";
import LoadingButton from "../../components/shared/LoadingButton";

const EditOrganization = ({ instance, superAdminShow, orgAdminShow }) => {
  const dispatch = useDispatch();
  const orgData = useSelector((state) => state.organization.data);
  const selectedOrg = useSelector((state) => state.organization.selectedOrg);
  const { location, primaryContact: primaryContactInfo } = selectedOrg;
  const [show, setShow] = useState(false);
  const { formData, changeHandler, submitChangedOrgData, loading } =
    useChangeOrgData(instance);

  function setNewUser(newUser, boolean) {
    newUser.isAdmin = boolean;
    return newUser;
  }

  /* This function handles changing the user's permission level and also
  preserving their original index in the users array of their organization. */

  async function handleUserAccessLevel(e) {
    let selectedUser = selectedOrg.users.filter(
      (user) => user.id === e.target.id
    );
    const selectedUserIndex = selectedOrg.users.indexOf(selectedUser);
    let otherUsers = [
      ...selectedOrg.users.filter((user) => user.id !== e.target.id),
    ];
    let newSelectedOrg = { ...selectedOrg };
    selectedUser = { ...selectedUser[0] };
    if (e.target.title === "user") {
      let newUserPermission = setNewUser(selectedUser, false);
      otherUsers.splice(selectedUserIndex, 0, newUserPermission);
      const newUsers = otherUsers;
      newSelectedOrg.users = newUsers;
      dispatch(setSelectedOrg(newSelectedOrg));
      const data = await fetchWithAuth(
        instance,
        "PUT",
        `api/organization/${selectedOrg.id}/user/${selectedUser.id}`,
        newUserPermission
      );
      let otherOrgs = [...orgData.filter((org) => org.id !== selectedOrg.id)];
      otherOrgs.push(newSelectedOrg);
      const newOrgs = otherOrgs;
      dispatch(setOrgData(newOrgs));
    } else if (e.target.title === "Administrator") {
      let newUserPermission = setNewUser(selectedUser, true);
      otherUsers.splice(selectedUserIndex, 0, newUserPermission);
      const newUsers = otherUsers;
      newSelectedOrg.users = newUsers;
      dispatch(setSelectedOrg(newSelectedOrg));
      const data = await fetchWithAuth(
        instance,
        "PUT",
        `api/organization/${selectedOrg.id}/user/${selectedUser.id}`,
        newUserPermission
      );
      let otherOrgs = [...orgData.filter((org) => org.id !== selectedOrg.id)];
      otherOrgs.push(newSelectedOrg);
      const newOrgs = otherOrgs;
      dispatch(setOrgData(newOrgs));
    } else {
      return null;
    }
  }

  async function handleUserDelete(e) {
    let selectedUser = selectedOrg.users.filter(
      (user) => user.id === e.target.id
    );
    selectedUser = { ...selectedUser[0] };
    let otherUsers = [
      ...selectedOrg.users.filter((user) => user.id !== e.target.id),
    ];
    let newSelectedOrg = { ...selectedOrg };
    const newUsers = otherUsers;
    newSelectedOrg.users = newUsers;
    dispatch(setSelectedOrg(newSelectedOrg));
    const data = await fetchWithAuth(
      instance,
      "DELETE",
      `api/organization/${selectedOrg.id}/user/${selectedUser.id}`,
      selectedUser
    );
    let otherOrgs = [...orgData.filter((org) => org.id !== selectedOrg.id)];
    otherOrgs.push(newSelectedOrg);
    const newOrgs = otherOrgs;
    dispatch(setOrgData(newOrgs));
  }

  return (
    <div>
      <br></br>
      <div className="edit-organization">
        <p>
          <Link to="/" style={{ textDecoration: "none" }}>
            Organization Dashboard
          </Link>
          &gt; Edit Organization
        </p>
        <br></br>
        <h2>Edit {selectedOrg.name}</h2>
        <Row>
          <Col xl lg="4">
            <div className="org-info">
              <div className="info-head">
                <h4 className="info-text">Information</h4>

                {(superAdminShow || orgAdminShow) && (
                  <LoadingButton
                    onClick={submitChangedOrgData}
                    loading={loading}
                    style={{
                      width: "100px",
                      height: "30px",
                      padding: "0",
                      backgroundColor: "#31387d",
                      border: "none",
                    }}
                  >
                    Save
                  </LoadingButton>
                )}
              </div>
              <form>
                <p className="input-name">
                  <Icon icon="eos-icons:trusted-organization" /> Organization
                  Name
                </p>
                <input
                  type="text"
                  className="org-edit-box"
                  placeholder={selectedOrg.name}
                  name="name"
                  value={formData.name}
                  onChange={changeHandler}
                ></input>
                <p className="input-name">Description</p>
                <textarea
                  type="text"
                  className="description-edit-box"
                  placeholder={
                    selectedOrg.description
                      ? selectedOrg.description
                      : "Please enter a description"
                  }
                  name="description"
                  value={formData.description}
                  onChange={changeHandler}
                ></textarea>
                <p className="input-name">Address</p>
                <textarea
                  type="text"
                  placeholder={
                    location?.address1
                      ? location?.address1
                      : "Please enter an address"
                  }
                  className="address-edit-box"
                  name="address"
                  value={formData.address}
                  onChange={changeHandler}
                ></textarea>

                <p className="input-name">
                  <Icon icon="mdi:contact" /> Primary Contact Title
                </p>
                <input
                  type="text"
                  className="org-edit-box"
                  placeholder={
                    primaryContactInfo.title
                      ? primaryContactInfo.title
                      : "Enter Primary Contact Title"
                  }
                  name="primaryContactTitle"
                  value={formData.primaryContactTitle}
                  onChange={changeHandler}
                ></input>
                <p className="input-name">
                  <Icon icon="mdi:contact" /> Primary Contact Name
                </p>
                <input
                  type="text"
                  className="org-edit-box"
                  placeholder={
                    primaryContactInfo.name
                      ? primaryContactInfo.name
                      : "Enter Primary Contact Name"
                  }
                  name="primaryContactName"
                  value={formData.primaryContactName}
                  onChange={changeHandler}
                ></input>
                <p className="input-name">
                  <Icon icon="mdi:contact" /> Primary Contact Email
                </p>
                <input
                  type="text"
                  className="org-edit-box"
                  placeholder={
                    primaryContactInfo.email
                      ? primaryContactInfo.email
                      : "Enter Primary Contact Email"
                  }
                  name="primaryContactEmail"
                  value={formData.primaryContactEmail}
                  onChange={changeHandler}
                ></input>
                <p className="input-name">
                  {" "}
                  <Icon icon="mdi:contact" /> Primary Contact Phone Number
                </p>
                <input
                  type="text"
                  className="org-edit-box"
                  placeholder={
                    primaryContactInfo.phoneNumber
                      ? primaryContactInfo.phoneNumber
                      : "Enter Primary Contact Phone Number"
                  }
                  name="primaryContactPhoneNumber"
                  value={formData.primaryContactPhoneNumber}
                  onChange={changeHandler}
                ></input>
              </form>
            </div>
          </Col>
          <Col>
            <div className="user-container">
              <table className="user-table">
                <thead className="user-head">
                  {(orgAdminShow || superAdminShow) && (
                    <div className="user-info-head">
                      <h4 className="user-title">Users</h4>
                      <button
                        className="add-user"
                        onClick={() => setShow(true)}
                      >
                        + Add New
                      </button>
                    </div>
                  )}
                </thead>
                <tbody>
                  <tr className="table-data">
                    <th>Name</th>
                    <th>Email</th>
                    <th>Permisssion Level</th>
                  </tr>
                  {selectedOrg?.users?.map((user) =>
                    user ? (
                      <tr
                        key={[
                          user.firstName,
                          user.lastName,
                          user.email,
                          user.isAdmin,
                        ]}
                      >
                        <td className="table-data">{`${user.firstName} ${user.lastName}`}</td>
                        <td className="table-data">{user.email}</td>

                        <td className="table-data">
                          {orgAdminShow || superAdminShow ? (
                            user.isAdmin ? (
                              <DropdownButton title="Administrator">
                                <Dropdown.Item
                                  onClick={handleUserAccessLevel}
                                  id={user.id}
                                  title="user"
                                >
                                  User
                                </Dropdown.Item>
                              </DropdownButton>
                            ) : (
                              <DropdownButton title="User">
                                <Dropdown.Item
                                  onClick={handleUserAccessLevel}
                                  id={user.id}
                                  title="Administrator"
                                >
                                  Administrator
                                </Dropdown.Item>
                              </DropdownButton>
                            )
                          ) : user.isAdmin ? (
                            <>Administrator</>
                          ) : (
                            <>User</>
                          )}
                        </td>
                        {(orgAdminShow || superAdminShow) && (
                          <td className="table-data">
                            <Icon
                              cursor="pointer"
                              id={user.id}
                              icon="ph:x"
                              onClick={handleUserDelete}
                            />
                          </td>
                        )}
                      </tr>
                    ) : null
                  )}
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
        <br></br>
      </div>
      {show ? (
        <NewUser
          show={show}
          instance={instance}
          openModal={() => setShow(true)}
          closeModal={() => setShow(false)}
        />
      ) : null}
    </div>
  );
};

export default EditOrganization;
