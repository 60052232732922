import React, { useState, useEffect } from "react";
import { fetchWithAuth } from "./../../utils/authPopup.js";
import { useSelector, useDispatch } from "react-redux";
import UniversalSearch from "../../components/shared/UniversalSearch";
import SuspectsTable from "../../components/Suspects/SuspectsTable";
import { SuspectsListContainer } from "./SuspectsList.styled";
import blur from "../../../src/images/blur_suspect.png";
import blur2 from "../../../src/images/suspectacessdenied.png";

import {
  setSuspectData,
  setFormattedSuspectData,
} from "../../redux/slices/suspect.js";

export default function SuspectsList({ instance, idd }) {
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const selectedOrg = useSelector((state) => state.organization.selectedOrg);
  const [suspects, setSuspects] = useState([]);
  const [filteredSuspects, setFilteredSuspects] = useState(suspects);
  async function getSuspects(msalContext, orgId) {
    setSuspects([]);
    setError(false);
    try {
      const data = await fetchWithAuth(
        msalContext,
        "GET",
        `api/organization/${orgId}/suspects`
      );

      const parsedData = data.map((item, index) => {
        // Parse the last updated date/time into a more readable format based on locale
        const lastUpdatedDateTime = new Date(item.lastUpdatedDate);
        const lastUpdatedDate = lastUpdatedDateTime.toLocaleDateString();
        const lastUpdatedTime = lastUpdatedDateTime.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        });

        return {
          name: item.name,
          operationId: item.operations[0].id,
          operation: item.operations[0].name,
          suspectRiskLevel: item.suspectRiskLevel,
          formattedPhoneNumber: item.formattedPhoneNumber,
          carrier: item.properties.Carrier,
          ageRange: item.properties.AgeRange,
          totalMessages: "TBD",
          id: item.id, // TODO: Use suspectId from fetched data. However, it is currently all 0s, which needs to be fixed
          totalBotsContacted: item.botIds.length,
          lastContactedDate: lastUpdatedDate,
          lastContactedTime: lastUpdatedTime,
          notes: item.notes,
          totalOperationCount: item.operationIds.length,
          totalOperations: item.operations,
          orgId: item.organizationId,
          lastReportedCallerIdInfo: item.whitePages,
          lastUpdatedCallerId: item.whitePages,
          lastUpdatedCriminalSearch: item.whitePages,
          therapeuticMessageSent: item.whitePages,
        };
      });

      setSuspects(parsedData);
      dispatch(setFormattedSuspectData(parsedData));
      dispatch(setSuspectData(data));
    } catch (error) {
      if (error == "Forbidden") {
        setError(true);
      }
    }
  }

  const filterSuspects = (query) => {
    if (!query) {
      setFilteredSuspects(suspects);
    } else {
      const filtered = suspects.filter((item) =>
        // Check if any field in the suspect object contains the query
        Object.values(item).some((field) =>
          field?.toString()?.toLowerCase()?.includes(query.toLowerCase())
        )
      );
      setFilteredSuspects(filtered);
    }
  };

  useEffect(() => {
    getSuspects(instance, idd);
  }, [selectedOrg]);

  useEffect(() => {
    setFilteredSuspects(suspects);
  }, [suspects]);

  return (
    <SuspectsListContainer>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h1>All Suspects</h1>
        <UniversalSearch
          placeholder={"Name, Neighborhood, ...etc"}
          filterFunction={filterSuspects}
        />
      </div>

      {suspects.length > 0 ? (
        <SuspectsTable suspects={filteredSuspects} />
      ) : error ? (
        <img src={blur2} width={"100%"} />
      ) : (
        <img style={{ width: "100%" }} src={blur} />
      )}
    </SuspectsListContainer>
  );
}
