import { Icon } from '@iconify/react';
import styled from 'styled-components';

export const UniversalNavContainer = styled.div`
    display: flex;
    /* equal height of the children */
    justify-content: space-evenly;
`;

export const UniversalNavButton = styled.button`
    background-color: ${props => props.active ? '#23263F' : '#2A2F62'};
    font-weight: ${props => props.active ? 'bold' : 'normal'};
    border: none;
    border-radius: 10px;
    color: white;
    padding: 5px;
    width: 13%;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    overflow: hidden;
`;

export const UniversalIcon = styled(Icon)`
    margin: 3px 5px 5px 5px;

`;