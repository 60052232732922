import { useState, useEffect, useRef } from "react";

const useChatListScroll = (fetchChatConvos, hasMoreConvos) => {
  const chatListRef = useRef(null);
  const [isBottom, setIsBottom] = useState(false);

  const handleScroll = () => {
    const scrollTop = chatListRef.current.scrollTop;
    const scrollHeight = chatListRef.current.scrollHeight;
    const clientHeight = chatListRef.current.clientHeight;
    const scrolledToBottom =
      Math.ceil(scrollTop + clientHeight) >= scrollHeight;

    if (scrolledToBottom) {
      setIsBottom(true);
    } else {
      setIsBottom(false);
    }
  };

  useEffect(() => {
    if (isBottom && hasMoreConvos) {
      fetchChatConvos();
    }
  }, [isBottom]);

  return { chatListRef, handleScroll };
};

export default useChatListScroll;
