import styled from "styled-components";

export const ChatListContainer = styled.div`
  flex: 2;
  margin-left: 1px;
  margin-right: 2px;
  display: flex;
  flex-direction: column;
`;

export const ChatInfoContainer = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
`;

export const SideBarContainer = styled.div`
  background-color: ${(props) =>
    props.theme === "light" ? "#f4f3f8" : "#2a2f62"};
  height: calc(100vh - 230px);
  border-radius: 10px;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  flex: 0.1;
  margin-left: 20px;
`;

export const ChatPageWrapper = styled.div`
display: flex;
flex-direction: row;
align-items: stretch;
@media (max-width: 768px) 
    flex-direction: column; 
  }

  ${ChatListContainer}, ${ChatInfoContainer} {
    flex: 1; 
    margin-left: 0; 
    margin-right: 0; 
  }

  ${SideBarContainer} {
    margin-left: 0;
  }
}
`;

export const ChatPageContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;

  @media (max-width: 768px) {
    flex-direction: column;

    ${ChatListContainer}, ${ChatInfoContainer} {
      margin-left: 0;
      margin-right: 0;
    }

    ${SideBarContainer} {
      margin-left: 0;
      width: 100%;
    }
  }
`;
