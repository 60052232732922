import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { useSelector } from "react-redux";
import {
  HeaderContainer,
  ChatListContainer,
  ListGroupContainer,
  ListItemContainer,
  AllLabel,
  SearchBox,
} from "./ChatList.styled";
import { fetchWithAuth } from "../../utils/authPopup.js";
import ChatFilterSelectors from "./ChatFilterSelectors.js";
import useChatListScroll from "./hooks/useChatListScroll.jsx";
import convoSeen from "./utils/convoSeen.js";

const ChatList = ({
  conversations,
  setConversations,
  fetchChatConvos,
  hasMoreConvos,
  selectedChat,
  setSelectedChat,
  flagged,
  instance,
  takeOverFilter,
  organizationId,
  allConversation,
  selectedOperation,
  setSelectedOperation,
  selectedBot,
  setSelectedBot,
}) => {
  const theme = useSelector((state) => state.theme);

  const [searchItem, setSearchItem] = useState("");
  const [onlyActiveOperations, setOnlyActiveOperations] = useState(false);
  const [filteredConversations, setFilteredConversations] = useState([
    ...conversations,
  ]);

  const { chatListRef, handleScroll } = useChatListScroll(
    fetchChatConvos,
    hasMoreConvos
  );

  const handleSearchChange = (event) => {
    setSearchItem(event.target.value);
  };

  const handleFilterChange = () => {
    setSelectedChat(null);
  };

  useEffect(() => {
    const filterBySearchItem = (item) =>
      item.suspectName?.toLowerCase().includes(searchItem.toLowerCase()) ??
      true;
    const filterByStatus = (item) =>
      onlyActiveOperations ? item.isOperationActive : true;
    const filterByTakeOver = (item) =>
      takeOverFilter ? item.humanTakeover : true;
    const filterByFlagged = (item) => (flagged ? item.flagged : true);
    const filterByAllConversation = (item) => allConversation !== true;

    const filteredChatItems = conversations?.filter(
      (item) =>
        filterBySearchItem(item) &&
        filterByStatus(item) &&
        filterByTakeOver(item) &&
        filterByFlagged(item) &&
        filterByAllConversation(item)
    );

    setFilteredConversations(filteredChatItems);
  }, [
    allConversation,
    conversations,
    flagged,
    onlyActiveOperations,
    searchItem,
    takeOverFilter,
  ]);

  const handleTime = (time) => {
    return new Date(time).toLocaleTimeString();
  };

  const handleFlag = async (itemId, item) => {
    const updatedConversations = conversations.map((conversation) =>
      conversation.id === itemId
        ? { ...conversation, flagged: !conversation.flagged }
        : conversation
    );

    setConversations(updatedConversations);

    await fetchWithAuth(
      instance,
      "PUT",
      `api/organization/${organizationId}/conversations/${itemId}/flag/${!item.flagged}`
    );
  };

  const handleConversationClick = async (item) => {
    setSelectedChat(item);

    if (item.unreadMessages === 0) return;

    convoSeen(instance, setConversations, organizationId, item);
  };

  return (
    <>
      <ChatListContainer
        ref={chatListRef}
        onScroll={handleScroll}
        theme={theme}
      >
        <HeaderContainer theme={theme}>
          <AllLabel>All</AllLabel>
          <SearchBox
            placeholder="Search..."
            onChange={handleSearchChange}
            value={searchItem}
            style={{ color: theme === "light" ? "#000000" : "#ffffff" }}
            theme={theme}
          />
        </HeaderContainer>
        <ChatFilterSelectors
          selectedOperation={selectedOperation}
          setSelectedOperation={setSelectedOperation}
          selectedBot={selectedBot}
          setSelectedBot={setSelectedBot}
          onFilterChange={handleFilterChange}
          onlyActiveOperations={onlyActiveOperations}
          setOnlyActiveOperations={setOnlyActiveOperations}
        />
        <ListGroupContainer spacing={3} theme={theme}>
          {filteredConversations?.map((item, index) => {
            const boldStyle = {};

            if (item.unreadMessages > 0 && item.id !== selectedChat?.id) {
              boldStyle.fontWeight = "bold";
            }

            let itemColor;
            if (theme === "light") {
              itemColor = item.id === selectedChat?.id ? "#C3C2C6" : "#F4F3F8";
            } else if (theme === "dark") {
              itemColor = item.id === selectedChat?.id ? "#1C1F3C" : "#2A2F62";
            } else {
              itemColor = "#2A2F62";
            }

            return (
              <div key={index}>
                <ListItemContainer
                  onClick={() => handleConversationClick(item)}
                  style={{
                    backgroundColor: itemColor,
                  }}
                >
                  <span>
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        ...boldStyle,
                      }}
                    >
                      <Icon
                        onClick={() => handleFlag(item.id, item)}
                        icon={item.flagged ? "bxs:label" : "bx:label"}
                        style={{ marginRight: "8px" }}
                      />
                      {item.suspectName
                        ? item.suspectName
                        : item.suspectPhoneNumber}
                      {/* <span
                      style={{
                        fontSize: "0.7rem",
                        color: "yellow",
                        marginLeft: "7px",
                      }}
                    >
                      {item.suspectRisk}
                    </span> */}
                    </span>
                    <div
                      style={{
                        fontSize: "0.6em",
                        color: "#AAAAAA",
                        ...boldStyle,
                      }}
                    >
                      {handleTime(item.lastMessageDate)}
                    </div>
                  </span>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      fontSize: "0.8rem",
                      alignItems: "end",
                    }}
                  >
                    <span style={boldStyle}>{item.botName}</span>
                    <span style={boldStyle}>{item.botNumber}</span>
                  </div>
                </ListItemContainer>
              </div>
            );
          })}
        </ListGroupContainer>
      </ChatListContainer>
    </>
  );
};

export default ChatList;
