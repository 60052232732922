import styled from 'styled-components';

export const PaginationContainer = styled.div`
  font-size: 16px;
  text-align: right;
  margin-left: auto;
  margin-right: 0;
`;

export const PaginationButton = styled.button`
  background-color: transparent;
  color: white;
  border: none;
  cursor: pointer;
`;

export const DisabledPaginationButton = styled(PaginationButton)`
  background-color: #282C4D;
  cursor: not-allowed;
`;