import styled from "styled-components";
import {
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "../shared/UniversalTable.styled";

export const STTableContainer = styled(TableContainer)`
  background-color: ${(props) =>
    props.theme === "light" ? "#f4f3f8" : "#282C4D"};
`;

export const STTable = styled(Table)``;

export const STThead = styled(Thead)``;

export const STTbody = styled(Tbody)``;

export const STTr = styled(Tr)``;

export const STTh = styled(Th)``;

export const STTd = styled(Td)``;
